<template>
  <div class="classReport">
    <a-modal
      :closable="false"
      destroyOnClose
      :visible="isVisibleModalDetailListGrade"
      @cancel="handleToggleModalListGrade"
    >
      <list-grade-student
        v-if="isVisibleModalDetailListGrade"
        :type="payloadListGrade.type"
        :idKdMaster="payloadListGrade.idKdMaster"
        :idKelasTahunAjaran="payloadListGrade.idKelasTahunAjaran"
      />
      <template slot="footer">
        <a-button key="back" @click="handleToggleModalListGrade">
          Close
        </a-button>
        <a-button class="d-none" key="submit" type="primary"> Submit </a-button>
      </template>
    </a-modal>
    <a-modal
      :visible="modalConfirmUpdateReport"
      :confirm-loading="loading.updateLoading"
      @ok="handleOkUpdateReport"
      @cancel="modalConfirmUpdateReport = false"
      :maskStyle="{
        opacity: 0.5,
        backgroundColor: 'black',
      }"
      okText="Update"
      :okButtonProps="{
        props: {
          shape: 'round',
        },
      }"
      :cancelButtonProps="{
        props: {
          shape: 'round',
        },
      }"
    >
      <h3 slot="title" style="color: #faad14">
        <a-icon style="font-size: 1.2em" type="warning" /> UPDATE KD
        CONFIRMATION
      </h3>
      <p>
        Are you sure to update report? Your data before will be lost once you
        update.
      </p>
    </a-modal>
    <template v-if="generalData.kelas">
      <DrawerReport
        :visible="visibleDrawer"
        :dataDrawer="dataDrawer"
        :chartData="chartData"
        @on-close="onCloseDrawer"
      />
      <div class="coverDetail">
        <h2>
          {{ kelas }} |
          {{ generalData.mapel.nama }}
        </h2>
        <!-- <h4>{{this.detailReport.semester}}</h4> -->
      </div>
      <div class="contentDetail">
        <a-menu v-model="reportTime" mode="horizontal">
          <a-menu-item :disabled="disabledMenu" key="mid" class="subMenuDetail">
            MID REPORT
          </a-menu-item>
          <a-menu-item
            :disabled="disabledMenu"
            key="final"
            class="subMenuDetail"
            >FINAL REPORT</a-menu-item
          >
        </a-menu>
        <!-- <p v-if="reportSubmissionStatus">{{reportSubmissionStatus}}</p> -->
        <LoadingState v-if="loading.loadingAll" />
        <template v-else>
          <a-empty
            class="my-4"
            v-if="!isKdMuridExist"
            :image-style="{ height: '100px' }"
          >
            <div slot="description">
              <span class="font-size-18" style="color: #0000009c">No Data</span>
              <br />
              <div style="color: #7474747a">
                It seems you are just getting started to fill report grading on
                {{ kelas }}.
              </div>
              <a-button
                :loading="loading.loadingIntitalize"
                @click.prevent="initializeKdMurid"
                type="primary"
                class="mt-3"
              >
                Start Initialize
              </a-button>
            </div>
          </a-empty>
          <template v-else>
            <a-alert
              v-if="reportSubmissionStatus.is_submission"
              message="REPORT SUBMISSION INFORMATION"
              :description="`You can only grade the report cards from ${parseMoment(
                reportSubmissionStatus.tanggal_awal_submisi
              )} to ${parseMoment(
                reportSubmissionStatus.tanggal_akhir_submisi
              )}`"
              type="info"
              show-icon
              class="mt-4"
              o
            />
            <a-alert
              v-else
              message="NO REPORT SUBMISSION TIME"
              :description="`You can't grade the report cards. For futher information, please contact the curriculum teacher.`"
              type="warning"
              show-icon
              class="mt-4"
            />
            <div class="d-md-flex align-items-center mb-3 mt-5">
              <a-form-item class="d-lg-block text-center">
                <a-radio-group
                  @change="handleReportType"
                  :value="reportType"
                  button-style="solid"
                  size="large"
                  :disabled="disableSelectType"
                >
                  <a-radio-button value="knowledge">{{
                    reportText["knowledge"]
                  }}</a-radio-button>
                  <a-radio-button value="skill">{{
                    reportText["skill"]
                  }}</a-radio-button>
                  <!-- <a-radio-button :value="(this.detailReport.semester==='Ganjil') ? 'PAS' : 'PAT'">
                      {{(this.detailReport.semester==='Ganjil') ? (this.textRadio2='PAS') : (this.textRadio2='PAT')}}
                    </a-radio-button> -->
                </a-radio-group>
              </a-form-item>
              <!-- <div
                class="d-md-flex ml-auto text-center"
                :style="{
                  'margin-top': isMobile ? '1em' : null,
                }"
              >
                <a-switch
                  @change="showMuridSesi = !showMuridSesi"
                  :disabled="disableShowDetails"
                  :checked="showMuridSesi"
                />
                <span class="ml-2"
                  >{{ showMuridSesi ? "Hide" : "Show" }} Details</span
                >
              </div> -->
              <!-- <a-form-item class="ml-auto">
                  <a-button
                    :disabled="loading.loadingTable"
                    @click.prevent="refreshTable"
                    type="default"
                    style="height: 40px"
                    block
                  >
                    <a-icon type="sync" :spin="loading.loadingRefresh" />Refresh
                  </a-button>
                </a-form-item> -->
            </div>
            <div class="d-flex flex-column flex-md-row align-items-center">
              <div class="w-100 w-lg-75">
                <a-divider orientation="left">
                  <h3>{{ firstLetterUpperReportType }} Reports</h3>
                </a-divider>
              </div>
              <div class="d-flex flex-column flex-md-row align-items-center">
                <!-- <div class="w-100 w-lg-75">
                  <a-divider orientation="left">
                    <h3>{{ firstLetterUpperReportType }} Reports</h3>
                  </a-divider>
                </div> -->
                <div class="w-auto d-flex flex-column flex-md-row ml-3">
                  <template v-if="!isEdit">
                    <a-button
                      :disabled="loading.loadingTable"
                      @click.prevent="syncCbt"
                      shape="round"
                      class="mb-3 mb-lg-0 mr-0 mr-md-3"
                    >
                      <a-icon type="sync" :spin="loading.loadingRefresh" />
                      SYNC</a-button
                    >
                    <a-button
                      @click.prevent="startEdit"
                      shape="round"
                      type="primary"
                      icon="edit"
                      class="mb-3"
                      >INPUT GRADE</a-button
                    >
                  </template>
                  <template v-else>
                    <a-button
                      @click.prevent="cancelEdit()"
                      :loading="loading.loadingEditReport"
                      type="danger"
                      shape="round"
                      icon="close"
                      class="mb-3 mb-lg-0 mr-0 mr-md-3"
                      >CANCEL</a-button
                    >
                    <a-button
                      @click.prevent="saveReport()"
                      shape="round"
                      class="mb-3"
                      icon="save"
                      style="background-color: #41b883; color: white"
                      >SAVE</a-button
                    >
                  </template>
                </div>
              </div>
            </div>
            <a-row>
              <!-- <a-col class="d-md-flex justify-content-md-center" :lg="10" :md="24">
                  <a-switch @change="showMuridSesi = !showMuridSesi" />
                </a-col>
                <a-col class="d-md-flex justify-content-md-center justify-content-lg-left" :lg="14" :md="24">
                  <div class="mx-2 mb-3" v-for="(note, i) in notes[reportType]" :key="i">{{note.code}}: {{note.full}}</div>
                  <div class="mx-2 mb-3" >Avg: Average</div>
                </a-col> -->
              <a-col :span="24">
                <a-collapse class="mb-3" accordion>
                  <a-collapse-panel key="1" header="Description">
                    <ul>
                      <li>
                        You can only update report between the time that is
                        defined by admin or vice principal of curriculum.
                      </li>
                      <li>
                        You can press <kbd>tab</kbd> &nbsp; on your keyboard to
                        move on next columns when updating grade.
                      </li>
                      <li>
                        You can only input on each sub KD, PTS, and PAS/PAT
                        skill. PTS, PAS/PAT knowledge are according to CBT's
                        grade on each type.
                      </li>
                      <li>
                        Grade on each cell can be empty, and each kd average
                        will automatically calculate unempty grade.
                      </li>
                      <!-- <li>You can sync PTS, PAS, or PAT on knowledge section with latest CBT grade on the same type by click sync ( <a-icon type="sync" /> ) at PTS's, PAS's, or PAT's column name on the table.</li> -->
                    </ul>
                    <div class="text-center mt-4"><b>NOTES: </b></div>
                    <div
                      class="
                        d-md-flex
                        justify-content-md-center justify-content-lg-left
                      "
                    >
                      <div
                        class="mx-2 mb-3"
                        v-for="(note, i) in notes[reportType]"
                        :key="i"
                      >
                        {{ note.code }}: {{ note.full }}
                      </div>
                      <div class="mx-2 mb-3">Avg: Average</div>
                    </div>
                  </a-collapse-panel>
                </a-collapse>
              </a-col>
            </a-row>
            <a-row :gutter="[16, 16]">
              <a-col
                :lg="8"
                :md="24"
                v-if="showMuridSesi"
                v-sticky="{ zIndex: 99, stickyTop: 10, disabled: !isDesktop }"
              >
                <div class="sesiDrawer p-4 scroll-custom">
                  <!-- <MenuAllSesiReport
                    :dataTable="dataTable"
                    :daftarMurid="daftarMurid"
                  /> -->
                </div>
              </a-col>
              <a-col class="colTable" :lg="showMuridSesi ? 16 : 24" :md="24">
                <a-table
                  class="table-scroll"
                  :pagination="{ pageSize: 30, hideOnSinglePage: true }"
                  :columns="column"
                  :data-source="dataTable"
                  :loading="loading.loadingTable"
                  :scroll="
                    listKd.length >= 2
                      ? { x: 100, y: 600 }
                      : { y: 600 }
                  "
                  bordered
                >
                  <span slot="titlePts"
                    ><a-icon
                      class="sync"
                      @click.prevent="syncCbt('pts')"
                      type="sync"
                    />
                    PTS</span
                  >
                  <span slot="titlePas"
                    ><a-icon
                      class="sync"
                      @click.prevent="syncCbt('pas')"
                      type="sync"
                    />
                    PAS</span
                  >
                  <span slot="titlePat"
                    ><a-icon
                      class="sync"
                      @click.prevent="syncCbt('pat')"
                      type="sync"
                    />
                    PAT</span
                  >

                  <div
                    v-if="isEdit"
                    slot="nilaiKd"
                    slot-scope="text, record"
                    class="d-flex align-items-center justify-content-center"
                  >
                    <a-input-number
                      :default-value="
                        text && text.data !== null ? text.data : null
                      "
                      :min="0"
                      :max="100"
                      :step="0"
                      class="inputNilai"
                      @change="(e) => handleChangeKdValue(e, record, text)"
                      style="height: 48px; width: 100%"
                    />
                  </div>
                  <div
                    v-else
                    slot="nilaiKd"
                    slot-scope="text"
                    class="d-flex align-items-center justify-content-center"
                  >
                    <!-- <span v-if="!isEdit" slot="nilaiKd" slot-scope="text"> -->
                    <a-tag v-if="!text || text.data === null">
                      <a-icon type="minus" />
                    </a-tag>
                    <a-tag :color="color(Number(text.data))" v-else>
                      {{ text.data }}
                    </a-tag>
                  </div>
                  <!-- <div slot="name" slot-scope="text">{{text}}</div> -->
                  <!-- <a
                        slot="Name"
                        class="text-primary"
                        slot-scope="text, record"
                        @click.prevent="showDrawer(record.key)"
                        style="width:120px;"
                      >{{ text }}</a> -->
                  <div
                    v-if="isEdit"
                    slot="assignment"
                    slot-scope="text, record"
                    class="d-flex align-items-center justify-content-center"
                  >
                    <a-input-number
                      :default-value="
                        text && text.data !== null ? text.data : null
                      "
                      :min="0"
                      :max="100"
                      :step="0"
                      class="inputNilai"
                      @change="(e) => handleChangeKdValue(e, record, text)"
                      style="height: 48px; width: 100%"
                    />
                    <a-icon
                      @click="
                        handleToggleModalListGrade(
                          'open',
                          'assignment',
                          record,
                          text.id
                        )
                      "
                      class="ml-2"
                      type="eye"
                    />
                  </div>
                  <div
                    v-else
                    slot="assignment"
                    slot-scope="text"
                    class="d-flex align-items-center justify-content-center"
                  >
                    <!-- <span v-if="!isEdit" slot="nilaiKd" slot-scope="text"> -->
                    <a-tag v-if="!text || text.data === null">
                      <a-icon type="minus" />
                    </a-tag>
                    <a-tag :color="color(Number(text.data))" v-else>
                      {{ text.data }}
                    </a-tag>
                    <a-icon
                      @click="
                        handleToggleModalListGrade(
                          'open',
                          'assignment',
                          record,
                          text.id
                        )
                      "
                      class="ml-2"
                      type="eye"
                    />
                  </div>
                  <div
                    v-if="isEdit"
                    slot="oral"
                    slot-scope="text, record"
                    class="d-flex align-items-center justify-content-center"
                  >
                    <a-input-number
                      :default-value="
                        text && text.data !== null ? text.data : null
                      "
                      :min="0"
                      :max="100"
                      :step="0"
                      class="inputNilai"
                      @change="(e) => handleChangeKdValue(e, record, text)"
                      style="height: 48px; width: 100%"
                    />
                  </div>
                  <div
                    v-else
                    slot="oral"
                    slot-scope="text"
                    class="d-flex align-items-center justify-content-center"
                  >
                    <!-- <span v-if="!isEdit" slot="nilaiKd" slot-scope="text"> -->
                    <a-tag v-if="!text || text.data === null">
                      <a-icon type="minus" />
                    </a-tag>
                    <a-tag :color="color(Number(text.data))" v-else>
                      {{ text.data }}
                    </a-tag>
                  </div>

                  <div slot="written" slot-scope="text, record">
                    <!-- <span v-if="!isEdit" slot="nilaiKd" slot-scope="text"> -->
                    <a-tag v-if="!text || text.data === null">
                      <a-icon type="minus" />
                    </a-tag>
                    <a-tag :color="color(Number(text.data))" v-else>
                      {{ text.data }}
                    </a-tag>
                    <a-icon
                      @click="
                        handleToggleModalListGrade(
                          'open',
                          'PH',
                          record,
                          text.id
                        )
                      "
                      class="ml-2 mt-1"
                      type="eye"
                    />
                  </div>

                  <div slot="nilaiUjian" slot-scope="text, record">
                    <a-input-number
                      v-if="isEdit && reportType === 'skill'"
                      :default-value="
                        text && text.data !== null ? text.data : null
                      "
                      :min="0"
                      :max="100"
                      class="inputNilai"
                      @change="(e) => handleChangeKdValue(e, record, text)"
                      style="height: 48px; width: 100%"
                    />
                    <template v-else>
                      <!-- <span v-if="!isEdit" slot="nilaiKd" slot-scope="text"> -->
                      <a-tag v-if="!text || text.data === null">
                        <a-icon type="minus" />
                        <!-- {{text}} -->
                      </a-tag>
                      <a-tag :color="color(Number(text.data))" v-else>
                        {{ text.data }}
                      </a-tag>
                      <a-icon
                      v-if="reportType === 'knowledge'"
                      @click="
                        handleToggleModalListGrade(
                          'open',
                          text.type,
                          record,
                          null
                        )
                      "
                      class="ml-2"
                      type="eye"
                    />
                    </template>
                  </div>

                  <span slot="kdAvg" slot-scope="text">
                    <a-tag v-if="!text || text.data === null">
                      <a-icon type="minus" />
                    </a-tag>
                    <a-tag :color="color(Number(text))" v-else>
                      {{ text.toFixed(1) }}
                    </a-tag>
                  </span>
                  <span slot="nilai" slot-scope="text">
                    <a-tag v-if="!text">
                      <a-icon type="minus" />
                    </a-tag>
                    <a-tag :color="color(Number(text))" v-else>
                      {{ text.toFixed(1) }}
                    </a-tag>
                  </span>
                </a-table>
              </a-col>
            </a-row>
          </template>
        </template>
      </div>
    </template>
  </div>
</template>
<script>

import moment from 'moment'
import { Empty } from 'ant-design-vue'
import VueSticky from 'vue-sticky'
const LoadingState = () => import('@/components/app/LoadingState')
const ListGradeStudent = () => import('@/components/app/ListGradeStudent')

const rawColumns = [
  {
    title: 'No',
    // width: '5%',
    width: 80,
    dataIndex: 'no',
    key: 'no',
    fixed: 'left',
    align: 'center',
  },
  {
    title: 'Name',
    // width: '100%',
    width: 370,
    dataIndex: 'nama',
    key: 'nama',
    fixed: 'left',
    ellipsis: true,
    // scopedSlots: { customRender: 'Name' },
  },
  {
    title: 'KD Avg',
    dataIndex: 'kdAvg',
    key: 'kdAvg',
    width: 80,
    align: 'center',
    scopedSlots: { customRender: 'nilai' },
  },
  {
    title: 'PTS',
    dataIndex: 'pts',
    key: 'pts',
    width: 100,
    align: 'center',
    // slots: { title: 'titlePts' },
    // fixed: 'right',
    scopedSlots: { customRender: 'nilaiUjian' },
  },
]

const knowledgeChildrenKDCol = [
  {
    title: 'A',
    dataIndex: 'assignment',
    width: 120,
    className: 'startFocus',
    scopedSlots: { customRender: 'assignment' },
    // scopedSlots: { customRender: 'nilaiKd' },
  },
  {
    title: 'W',
    dataIndex: 'written',
    width: 120,
    scopedSlots: { customRender: 'written' },
    // scopedSlots: { customRender: 'nilaiKd' },
    // scopedSlots: { customRender: 'nilaiUjian' },
  },
  {
    title: 'O',
    dataIndex: 'oral',
    width: 120,
    scopedSlots: { customRender: 'oral' },
    // scopedSlots: { customRender: 'nilaiKd' },
  },
]

const skillChildrenKDCol = [
  {
    title: 'Pra',
    dataIndex: 'practical',
    width: 80,
    className: 'startFocus',
    // scopedSlots: { customRender: 'practical' },
    scopedSlots: { customRender: 'nilaiKd' },
  },
  {
    title: 'Pro',
    dataIndex: 'project',
    width: 80,
    // scopedSlots: { customRender: 'project' },
    scopedSlots: { customRender: 'nilaiKd' },
  },
  {
    title: 'Oth',
    dataIndex: 'others',
    width: 80,
    // scopedSlots: { customRender: 'others' },
    scopedSlots: { customRender: 'nilaiKd' },
  },
]

export default {
  components: {
    DrawerReport: () => import('@/components/app/DrawerReport'),
    // MenuAllSesiReport: () => import('@/components/app/MenuAllSesiReport'),
    ListGradeStudent,
    LoadingState,
  },
  directives: {
    sticky: VueSticky,
  },
  // beforeRouteLeave (to, from, next) {
  //   if (this.isEdit) {
  //     const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
  //     if (answer) {
  //       window.removeEventListener('beforeunload', null)
  //       next()
  //     } else {
  //       // console.log(to, from)
  //       this.$store.commit('menu/SET_STATE', {
  //         currentMenu: ['Reports Teacher'],
  //       })
  //       next(false)
  //     }
  //   } else {
  //     window.removeEventListener('beforeunload', null)
  //     next()
  //   }
  // },
  // beforeDestroy() {
  //   window.removeEventListener('beforeunload', null)
  // },
  data() {
    return {
      editPH: false,
      modalVisible: false,
      visibleDrawer: false,
      disableSelectType: false,
      disabledMenu: false,
      loading: {
        confirmLoading: false,
        loadingCancel: false,
        loadingTable: false,
        loadingEditReport: false,
        loadingRefresh: false,
        updateLoading: false,
        loadingIntitalize: false,
        loadingAll: false,
      },
      dataTable: [],
      dataDetail: {},
      dataDrawer: {},
      reportSubmissionStatus: {
        is_submission: false,
        is_submission_time: false,
      },
      chartData: {
        labels: [],
        series: [],
      },
      savedData: [],
      reportType: 'knowledge',
      reportText: {
        knowledge: 'Knowledge',
        skill: 'Skill',
      },
      skillChildrenKDCol,
      knowledgeChildrenKDCol,
      isEdit: false,
      generalData: {},
      kdColumn: {
        title: 'KD',
        dataIndex: 'kd1',
        code: 'KD',
        key: 'KD',
        width: 400,
      },
      reportTime: ['mid'],
      modalConfirmUpdateReport: false,
      notes: {
        skill: [{
          code: 'Pra',
          full: 'Practical',
        }, {
          code: 'Pro',
          full: 'Project',
        }, {
          code: 'Oth',
          full: 'Others',
        }, {
          code: 'NK',
          full: 'Skill Grade (Nilai Keterampilan)',
        }],
        knowledge: [{
          code: 'W',
          full: 'Written',
        }, {
          code: 'A',
          full: 'Assignments',
        }, {
          code: 'O',
          full: 'Oral',
        }, {
          code: 'NP',
          full: 'Knowledge Grade (Nilai Pengetahuan)',
        }],
      },
      showMuridSesi: false,
      kdLength: 0,
      disableShowDetails: false,
      listKd: [],
      rawColumns,
      idMapel: this.$route.params.idMapel,
      idKelas: this.$route.params.idKelas,
      isKdMuridExist: false,
      startFocus: null,
      startInputFocus: null,
      isVisibleModalDetailListGrade: false,
      payloadListGrade: {
        idKelasTahunAjaran: null,
        idKdMaster: null,
        type: null,
      },
    }
  },
  methods: {
    handleToggleModalListGrade(event, typeRaw, record, idKdMaster) {
      this.isVisibleModalDetailListGrade = !this.isVisibleModalDetailListGrade
      if (event === 'open') {
        const idKelasTahunAjaran = record.id_kelas_tahun_ajaran
        const type = ['pts', 'pas'].includes(typeRaw.split('_')[0]) ? typeRaw.split('_')[0]?.toUpperCase() : typeRaw
        this.payloadListGrade = {
          idKelasTahunAjaran,
          idKdMaster,
          type,
        }
      } else {
        this.payloadListGrade = {
          idKelasTahunAjaran: null,
          idKdMaster: null,
          type: null,
        }
      }
    },
    setInputFocus(element) {
      for (let i = 0; i < element.childNodes.length; i++) {
        const node1 = element.childNodes[i]
        for (let j = 0; j < node1.childNodes.length; j++) {
          const node2 = node1.childNodes[j]
          for (let k = 0; k < node2.childNodes.length; k++) {
            const node3 = node2.childNodes[k]
            if (k === 1) {
              for (let l = 0; l < node3.childNodes.length; l++) {
                const node4 = node3.childNodes[l]
                return node4
              }
            }
          }
        }
      }
    },
    changeFocus(element) {
      if (element != null) {
        this.startFocus.focus()
        this.startFocus.style.backgroundColor = ''
        this.startFocus.style.color = ''
        this.startFocus.parentElement.style.backgroundColor = ''
        this.startFocus = element
        this.startFocus.parentElement.style.backgroundColor = '#BDDABA'
        this.startInputFocus = this.setInputFocus(this.startFocus) || null
        // console.log('this.startInputFocus', this.startInputFocus)
        if (this.startInputFocus) {
          element.focus()
          element.style.backgroundColor = 'green'
          element.style.color = 'white'
          // this.startInputFocus.disabled = false
          this.startInputFocus.focus()
        }
      }
    },
    async syncCbt(typeCbt) {
      try {
        this.loading.loadingRefresh = true
        this.loading.loadingTable = true
        await this.$store.dispatch('report/SYNC_CBT', { typeCbt, typeReport: this.reportType, idMapel: this.$route.params.idMapel, idKelas: this.$route.params.idKelas })
        this.fetchDataDetail()
      } catch (err) {
        console.log(err)
        this.fetchDataDetail()
      }
    },
    parseMoment(date) {
      return moment(date).format('DD MMMM YYYY HH:mm')
    },
    async handleOkUpdateReport() {
      try {
        const is200 = await this.$store.dispatch('report/UPDATE_REPORT_BY_CLASS_AND_MAPEL', {
          idMapel: this.$route.params.idMapel,
          savedData: this.savedData,
          tipe: this.reportType,
          time: this.reportTime[0],
        })
        if (is200) {
          setTimeout(() => {
            this.$notification.success({
              message: 'Successfuly updated score on this report.',
            })
            this.loading.updateLoading = false
            this.modalConfirmUpdateReport = false
            // resolve(data.flag)
          }, 1000)
        } else {
          this.loading.updateLoading = false
          this.modalConfirmUpdateReport = false
          this.$notification.error({
            message: 'Sorry.',
            description: 'There must be some error in the server. Please try again.',
          })
        }
        this.syncCbt()
      } catch (err) {
        this.syncCbt()
        console.log(err)
        return new Promise((resolve, reject) => reject(err))
      }
    },
    handleChangeKdValue(value = '', record, payload) {
      const newData = [...this.savedData]
      const target = newData.findIndex(item => record.id_kelas_tahun_ajaran === item.id_kelas_tahun_ajaran)
      // console.log(payload, record)
      if (target !== -1) {
        if (['pts_skill', 'pas_skill'].includes(payload.type)) {
          newData[target].report_mapel[payload.type] = value
        } else {
          const kd = newData[target].kd
          const indexKd = kd.findIndex(dat => dat.id_kd_master === payload.id)
          if (indexKd !== -1) kd[indexKd] = { ...kd[indexKd], [payload.type]: value }
          else {
            kd.push({
              id_kd_master: payload.id,
              [payload.type]: value,
            })
          }
        }
      } else {
        if (['pts_skill', 'pas_skill'].includes(payload.type)) {
          newData.push({
            id_kelas_tahun_ajaran: record.id_kelas_tahun_ajaran,
            report_mapel: { [payload.type]: value },
            kd: [],
          })
        } else {
          newData.push({
            id_kelas_tahun_ajaran: record.id_kelas_tahun_ajaran,
            kd: [
              { id_kd_master: payload.id, [payload.type]: value },
            ],
            report_mapel: {},
          })
        }
      }
      this.savedData = newData
      // console.log(this.savedData)
    },
    showDrawer(idMurid) {
      this.$store.dispatch('report/GET_SESI_BY_ID_MURID', { idMurid, idMapel: this.$route.params.idMapel })
        .then(data => {
          // console.log(data)
          const labels = []
          const series = []
          data.studentsChart.dataChart.forEach((el, index) => {
            labels.push(`#${++index}`)
            series.push(el.tugas_nilai)
          })
          const newData = [...this.dataTable]
          const target = newData.find(item => idMurid === item.key)
          this.dataDrawer = {
            ...target,
            ...data,
          }
          // console.log(target)
          this.chartData.labels = labels
          this.chartData.series[0] = series
          // console.log(this.chartData)
          // this.dataDrawer = data
          this.visibleDrawer = true
        })
      // const newData = [...this.dataTable]
      // const target = newData.filter(item => key === item.key)[0]
      // console.log(target)
      // const labels = []
      // const series = []
      // target.ListAssignment.forEach((el, index) => {
      //   labels.push(`#${++index}`)
      //   series.push(el.tugas_nilai)
      // })

      // this.chartData.labels = labels
      // this.chartData.series[0] = series
      // console.log(this.chartData)
      // this.dataDrawer = target
      // this.visibleDrawer = true
    },
    startEdit() {
      // console.log(this.reportSubmissionStatus)
      if (this.reportSubmissionStatus.is_submission && this.reportSubmissionStatus.is_submission_time) {
        this.isEdit = true
        this.disableSelectType = true
        this.disabledMenu = true
      } else {
        this.$notification.error({
          message: 'Sorry.',
          description: "You can't grade this report now, since now is not a submission time. Please contact your school's curriculum vice principal.",
        })
      }
    },
    cancelEdit() {
      this.isEdit = false
      this.savedData = []
      this.fetchDataDetail()
    },
    saveReport() { this.modalConfirmUpdateReport = true },
    refreshTable() {
      this.loading.loadingRefresh = true
      this.fetchDataDetail()
    },
    async fetchGeneralData() {
      try {
        const idMapel = this.$route.params.idMapel
        const idKelas = this.$route.params.idKelas
        this.loading.loadingTable = true
        const data = await this.$store.dispatch('report/FETCH_GENERAL_DATA_RAPORT_BY_CLASS_MAPEL', { idMapel, idKelas })
        this.generalData = data
        return new Promise((resolve, reject) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async fetchReportSubmission(reportTime) {
      try {
        const res = await this.$store.dispatch('report/FETCH_REPORT_SUBMISSION_ACTIVE', { reportType: reportTime, idKelas: this.idKelas })
        // console.log(res)
        this.reportSubmissionStatus = res
        return new Promise((resolve) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async fetchDataDetail() {
      try {
        const idMapel = this.$route.params.idMapel
        const idKelas = this.$route.params.idKelas
        const reportTime = this.reportTime[0]
        this.loading.updateLoading = true
        this.loading.loadingTable = true
        this.disableSelectType = true
        this.disabledMenu = true
        this.showMuridSesi = false
        this.disableShowDetails = true
        const { data, listKd, isKdMuridExist } = await this.$store.dispatch('report/FETCH_DETAIL_RAPORT', { idMapel, idKelas, reportType: reportTime, gradeTypeReport: this.reportType })

        this.listKd = listKd

        const childrenCol = this.reportType === 'knowledge' ? this.knowledgeChildrenKDCol : this.reportType === 'skill' ? this.skillChildrenKDCol : []
        const dataIndexChildrenCol = childrenCol.map(col => col.dataIndex)
        this.isKdMuridExist = isKdMuridExist
        this.dataTable = data.map((murid, i) => {
          const kd = {}
          const ptsPas = {}
          const dataReportMapel = murid.kelas_tahun_ajarans[0]?.report_mapels[0] || {}
          const dataKd = dataReportMapel.kd_murid_mapels || []
          if (!dataReportMapel.id) {
            ptsPas[`pts_${this.reportType}`] = {
              data: null,
              type: `pts_${this.reportType}`,
            }
            ptsPas[`pas_${this.reportType}`] = {
              data: null,
              type: `pts_${this.reportType}`,
            }
          } else {
            for (const key in dataReportMapel) {
              // if key is pts or pas in report type
              if ([`pts_${this.reportType}`, `pas_${this.reportType}`].includes(key)) {
                ptsPas[key] = {
                  data: dataReportMapel[key],
                  type: key,
                }
              } else if (key === `nilai_${this.tipeIndo}`) {
                ptsPas[key] = dataReportMapel[key]
              }
            }
          }

          this.listKd.forEach(kdMaster => {
            dataIndexChildrenCol.forEach(childCol => {
              kd[`${childCol}${kdMaster.no}`] = {
                id: kdMaster.id,
                type: childCol,
                data: null,
              }
            })
          })
          let amountKdFilled = 0
          let totalAvgKd = 0
          dataKd.forEach(kdMurid => {
            const kdMaster = this.listKd.find(kdMaster => kdMaster.id === kdMurid.id_kd_master)
            if (kdMaster) {
              if (kdMurid[`${this.reportType}_average`] !== null && kdMurid[`${this.reportType}_average`] !== undefined) {
                amountKdFilled++
                totalAvgKd += kdMurid[`${this.reportType}_average`]
              }
              for (const key in kdMurid) {
                if (dataIndexChildrenCol.includes(key)) {
                  kd[`${key}${kdMaster.no}`] = {
                    id: kdMaster.id,
                    data: kdMurid[key],
                    type: key,
                  }
                } else if (key === `${this.reportType}_average`) {
                  kd[`${key}${kdMaster.no}`] = kdMurid[key]
                }
              }
            }
          })
          let kdAvg
          if (amountKdFilled > 0) kdAvg = totalAvgKd / amountKdFilled
          return {
            ...murid,
            ...ptsPas,
            ...kd,
            kdAvg,
            key: murid.id,
            no: i + 1,
            id_kelas_tahun_ajaran: murid.kelas_tahun_ajarans[0].id,
          }
        })

        // console.log(this.dataTable)
        this.fetchReportSubmission(reportTime)
        this.disabledMenu = false
        this.loading.loadingCancel = false
        this.editPH = false
        this.loading.loadingTable = false
        this.loading.loadingRefresh = false
        this.disableSelectType = false
        this.modalConfirmUpdateReport = false
        this.disableShowDetails = false
        this.isEdit = false
        this.loading.updateLoading = false
        this.loading.loadingAll = false
        return new Promise((resolve) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    changeCode(code, index) {
      let codeIndex = ''
      switch (code) {
        case 'written' + index:
          codeIndex = 'written'
          break
        case 'assignment' + index:
          codeIndex = 'assignment'
          break
        case 'oral' + index:
          codeIndex = 'oral'
          break
        case 'practical' + index:
          codeIndex = 'practical'
          break
        case 'project' + index:
          codeIndex = 'project'
          break
        case 'others' + index:
          codeIndex = 'others'
          break
        default:
          codeIndex = ''
          break
      }

      return codeIndex
    },
    handleReportType(e) {
      const delay = t => new Promise(resolve => setTimeout(resolve, t))
      this.loading.loadingTable = true
      this.reportType = e.target.value
      this.reportText[this.reportType] = 'Please Wait...'
      delay(100).then(() => {
        this.reportText[this.reportType] = this.firstLetterUpperReportType
        this.loading.loadingTable = false
        this.fetchDataDetail({})
      })
    },
    onCloseDrawer(payload) {
      if (payload) {
        this.chartData.labels = []
        this.chartData.series = []
        this.visibleDrawer = false
      }
    },
    color(nilai) {
      // console.log(nilai)
      if (nilai < 25) {
        return '#FF0000'
      } else if (nilai >= 25 && nilai < 50) {
        return '#FF8811'
      } else if (nilai >= 50 && nilai < 75) {
        return '#F9E900'
      } else if (nilai >= 75 && nilai < 100) {
        return '#7ABE28'
      } else {
        return '#41B883'
      }
    },
    async initializeKdMurid() {
      try {
        this.loading.loadingIntitalize = true
        const { idMapel, idKelas } = this
        await this.$store.dispatch('report/CREATE_KD_MURID_BY_SUBJECT_AND_CLASS', { idMapel, idKelas })
        this.loading.loadingIntitalize = false
        this.$notification.success({
          message: 'Success',
        })
        this.loading.loadingAll = true
        this.syncCbt()
      } catch (err) {
        console.log(err)
        this.loading.loadingIntitalize = false
        this.$notification.error({
          message: 'Sorry.',
          description: 'There must be some error in the server. Please try again or contact admin.',
        })
        this.fetchDataDetail()
        return new Promise((resolve, reject) => reject(err))
      }
    },
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile
    },
    isDesktop() {
      // console.log(!this.$store.getters.isMobile && !this.$store.getters.isTablet)
      return !this.$store.getters.isMobile && !this.$store.getters.isTablet
    },
    firstLetterUpperReportType() {
      const first = this.reportType.split('')[0].toUpperCase()
      const withoutFirstLetter = this.reportType.split('').slice(1).join('')
      return first + withoutFirstLetter
    },
    daftarMurid() {
      if (this.dataTable.length) {
        return this.dataTable.map(data => {
          return {
            id: data.key,
            nama: data.Name,
          }
        })
      }
      return []
    },
    column() {
      let columns = []
      const kdCol = {
        title: 'KD-',
        code: 'KD',
        width: 320,
        children: [],
      }
      const indexNameCol = this.rawColumns.map(col => col.key).indexOf('nama')
      for (let i = 0; i < this.rawColumns.length; i++) {
        const col = this.rawColumns[i]
        const childrens = this.reportType === 'knowledge' ? this.knowledgeChildrenKDCol : this.reportType === 'skill' ? this.skillChildrenKDCol : []
        if (['no', 'nama'].includes(col.key) && this.listKd.length < 2) {
          columns.push({
            ...col,
            fixed: null,
          })
        } else {
          if (col.key === 'pts') {
            columns.push({
              ...col,
              dataIndex: `${col.dataIndex}_${this.reportType}`,
              key: `${col.key}_${this.reportType}`,
            })
          } else columns.push(col)
        }

        if (i === indexNameCol) {
          if (this.listKd.length) {
            const colKds = this.listKd.map(kd => {
              const children = childrens.map(child => {
                // const scopedSlots = child.dataIndex === 'written' ? {} : { scopedSlots: { customRender: 'nilaiKd' } }
                return {
                  ...child,
                  key: child.dataIndex + kd.no,
                  dataIndex: child.dataIndex + kd.no,
                  align: 'center',
                  // ...scopedSlots,
                }
              })
              children.push({
                title: 'Avg',
                dataIndex: `${this.reportType}_average${kd.no}`,
                key: `${this.reportType}_average${kd.no}`,
                width: 80,
                align: 'center',
                scopedSlots: { customRender: 'kdAvg' },
              })
              return {
                ...kdCol,
                title: `KD-${kd.no}`,
                key: `kd${kd.no}`,
                dataIndex: `kd${kd.no}`,
                children,
              }
            })
            columns = columns.concat(colKds)
          } else {
            columns.push({
              title: 'KD',
              key: 'kd',
              dataIndex: 'kd',
              align: 'center',
              width: 400,
              customRender: (col, row, index) => {
                if (index === 0) {
                  return {
                    children: (
                      <a-empty image={Empty.PRESENTED_IMAGE_SIMPLE}>
                        <div slot="description" class="value">
                          <span class="font-size-18" style="color: #0000009C">No KD</span>
                          <div class="font-size-16">
                            <span class="text-primary" onClick={e => {
                              e.preventDefault()
                              this.$store.commit('menu/SET_STATE', {
                                currentMenu: ['Planning Teacher'],
                              })
                              this.$router.push({ name: 'Planning Teacher' })
                            }}>Go to 'Planning Menu'</span> to manage Your KD.
                          </div>
                        </div>
                      </a-empty>
                    ),
                    attrs: {
                      rowSpan: this.dataTable.length,
                    },
                  }
                } else {
                  return {
                    attrs: {
                      rowSpan: 0,
                    },
                  }
                }
              },
            })
          }
        }
      }

      // check reportTime if final report so add col PAS and Nilai
      if (this.reportTime[0] === 'final') {
        // push col PAS
        columns.push({
          title: 'PAS',
          dataIndex: `pas_${this.reportType}`,
          key: `pas_${this.reportType}`,
          width: 80,
          align: 'center',
          scopedSlots: { customRender: 'nilaiUjian' },
        })
        // push col nilai_pengetahuan atau nilai_keterampilan
        columns.push({
          title: this.reportType === 'skill' ? 'NK' : this.reportType === 'knowledge' ? 'NP' : 'N',
          dataIndex: `nilai_${this.tipeIndo}`,
          key: `nilai_${this.tipeIndo}`,
          width: 80,
          align: 'center',
          scopedSlots: { customRender: 'nilai' },
        })
      }

      // console.log(columns)

      return columns
    },
    kelas() {
      return `${this.generalData.kelas?.level?.nama} - ${this.generalData.kelas?.simbol}`
    },
    tipeIndo() {
      return this.reportType === 'skill' ? 'keterampilan' : this.reportType === 'knowledge' ? 'pengetahuan' : null
    },
    firstLetterUpperTipeIndo() {
      const first = this.tipeIndo.split('')[0].toUpperCase()
      const withoutFirstLetter = this.tipeIndo.split('').slice(1).join('')
      return first + withoutFirstLetter
    },
  },
  async created() {
    try {
      this.loading.loadingAll = true
      await this.fetchGeneralData()
      await this.fetchDataDetail()
      this.loading.loadingAll = false

      // if (this.dataTable.length) {
      //   setTimeout(() => {
      //     this.startFocus = document.getElementsByClassName('startFocus')[3]
      //     // this.startInputFocus = this.startFocus.childNodes[0].childNodes[0].childNodes[1].childNodes[0]
      //     this.startFocus.style.backgroundColor = 'green'
      //     this.startFocus.focus()
      //     this.startFocus.style.color = 'white'
      //     this.startInputFocus = this.setInputFocus(this.startFocus)
      //     this.startInputFocus.focus()
      //     this.startFocus.parentElement.style.backgroundColor = '#BDDABA'
      //     this.startInputFocus.parentElement.addEventListener('keydown', (e) => {
      //       e.preventDefault()
      //     })
      //   }, 100)
      //   // startInput.style.backgroundColor = 'red'

      //   document.onkeydown = (event) => {
      //     this.startInputFocus.parentElement.addEventListener('keydown', (e) => {
      //       e.preventDefault()
      //     })
      //     event = event || window.event
      //     if (event.keyCode === 9) {
      //       event.preventDefault()
      //       // UP
      //     } else if (event.keyCode === 38) {
      //       const idx = this.startFocus.cellIndex
      //       const nextRow = this.startFocus.parentElement.previousElementSibling
      //       if (nextRow) {
      //         this.startInputFocus.disabled = true
      //         const element = nextRow.cells[idx]
      //         this.changeFocus(element)
      //       }
      //     // DOWN
      //     } else if (event.keyCode === 40) {
      //       const idx = this.startFocus.cellIndex
      //       const nextrow = this.startFocus.parentElement.nextElementSibling
      //       if (nextrow != null) {
      //         this.startInputFocus.disabled = true
      //         const element = nextrow.cells[idx]
      //         this.changeFocus(element)
      //       }
      //     // LEFT
      //     } else if (event.keyCode === 37) {
      //       const sibling = this.startFocus.previousElementSibling
      //       this.changeFocus(sibling)
      //     // RIGHT
      //     } else if (event.keyCode === 39) {
      //       const sibling = this.startFocus.nextElementSibling
      //       this.changeFocus(sibling)
      //     }
      //   }
      // }
    } catch (err) {
      console.log(err)
    }
    // this.fetchDataDetail()
  },
  watch: {
    reportTime(newVal, oldVal) {
      this.fetchDataDetail()
    },
    showMuridSesi(newVal) {
      // console.log(this.kdLength)
      if (newVal) {
        this.column = this.column.map((col, i) => {
          return {
            ...col,
            fixed: this.kdLength > 1 && i <= 1 ? 'left' : null,
          }
        })
      } else {
        this.column = this.column.map((col, i) => {
          return {
            ...col,
            fixed: this.kdLength > 1 && i <= 1 ? 'left' : null,
          }
        })
      }
    },
    // isEdit(newVal) {
    //   if (newVal) {
    //     window.addEventListener('beforeunload', function(event) {
    //       event.returnValue = 'Are you sure to leave this page? You have undone form here.'
    //     })
    //   } else {
    //     window.removeEventListener('beforeunload', null)
    //   }
    // },
  },
}
</script>
<style lang="scss">
.classReport {
  .coverDetail {
    width: 100%;
    min-height: 145px;
    background-color: var(--kit-color-primary);
    border-radius: 10px;
    padding: 40px;
    h2 {
      color: #ffffff;
    }
  }

  .contentDetail {
    width: 100%;
    min-height: 20vh;
    background-color: white;
    padding: 40px;
    z-index: 11;
    margin-top: -10px;
    border-radius: 10px;
    box-shadow: 0 0 10px #0419301a;
    margin-left: 1px;
    .value span {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    .sesiDrawer {
      // width: 100%;
      // height: 100%;
      border: 1px solid #e8e8e8;
      height: 45rem;
      overflow-y: auto;
      overflow-x: hidden;
      border-radius: 5px;
    }

    .ant-form-item {
      margin-bottom: 0;
    }

    kbd {
      background-color: #eee;
      border-radius: 3px;
      border: 1px solid #b4b4b4;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2),
        0 2px 0 0 rgba(255, 255, 255, 0.7) inset;
      color: #333;
      display: inline-block;
      font-size: 0.85em;
      font-weight: 700;
      line-height: 1;
      padding: 2px 4px;
      white-space: nowrap;
    }
    .inputNilai {
      .ant-input-number-handler-wrap {
        display: none;
      }
      .ant-input-number-input-wrap {
        height: 100%;
        .ant-input-number-input {
          height: 100%;
        }
      }
    }
    .colTable {
      overflow-x: auto;
      .sync {
        color: var(--kit-color-primary);
        &:hover {
          transform: scale(1.15);
        }
      }
      .table-scroll {
        .ant-spin-nested-loading .ant-spin-container .ant-table {
          overflow-x: auto;
          // .ant-table-row-cell-ellipsis {
          //   @media (max-width: 769px) {
          //     width: 200px;
          //   }
          // }
        }
      }
    }
  }
}
</style>
